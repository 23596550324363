.home {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1rem;
  color: black;
  background-color: #000000;
  .header {
    padding: 0.38rem 3.2rem;
    width: 100%;
    .container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      .logo {
        display: flex;
        img {
          width: 1.8rem;
          height: 0.28rem;
        }
      }
    }
  }
  .main {
    flex: 1;
    padding: 0 3.2rem;
    width: 100%;
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      .left {
        margin-top: -0.3rem;
        .title {
          font-family: HarmonyOS Sans SC;
          font-size: 0.8rem;
          font-weight: 500;
          letter-spacing: 0px;
          color: #ffffff;
        }
        .btn_group {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin: 0 -0.12rem;
          margin-top: 0.67rem;
          button {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0.12rem 0;
            padding-left: 0.28rem;
            padding-right: 0.4rem;
            border-radius: 0.4rem;
            border: none;
            // min-width: 2.2rem;
            box-sizing: content-box;
            background-color: rgb(255, 255, 255);
            color: rgb(0, 0, 0);
            margin: 0 0.16rem;
            cursor: pointer;
            img {
              width: 0.26rem;
              min-width: 20px;
              height: auto;
              margin-right: 0.19rem;
            }
            .text {
              text-align: left;
              p {
                font-family: "HSSRegular";
                font-size: 0.14rem;
                line-height: 1.2;
                margin: 0;
              }
              .name {
                font-family: "HSSBlod";
                font-weight: bold;
                font-size: 0.18rem;
                line-height: 1.2;
              }
            }
          }
        }
      }
      .right {
        margin-right: 0.5rem;
        img {
          width: 4.51rem;
          height: 7.98rem;
        }
      }
    }
  }
  .footer {
    padding: 0 3.2rem;
    width: 100%;
    padding-bottom: 0.42rem;
    .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #fff;
      font-size: 0.12rem;
      line-height: 1.4;
      font-family: "HSSMedium";
      .left {
        .nav {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .item {
            position: relative;
            &:hover {
              text-decoration: underline;
            }
          }
          .shu {
            width: 0.01rem;
            height: 0.14rem;
            background-color: #5e5e5e;
            margin: 0 0.25rem;
          }
        }
      }
    }
  }
}
