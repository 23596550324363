.mobileHome{
  background-size: contain;
  background-color: #000;
  width: 100%;
  padding-top: 0.6rem;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .header{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    background-color: #000;
    height: .6rem;
    z-index: 1400;
    transition: all linear .3s;
    .logo{
      height: .6rem;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 1rem;
        height: 0.155rem;
      }
      path{
        fill: #fff;
        transition: color linear 0.3s;
      }
      line{
        stroke: #fff;
        transition: color linear 0.3s;
      }
    }
    &.scroll{
      background-color: #232323;
      .header-menu-mobile .line{
        stroke: #fff;
      }
      .logo{
        path{
          fill: #fff;
        }
        line{
          stroke: #fff;
        }
      }
    }
  }
  .banner{
    flex: 1;
    .title{
      color: #fff;
      font-size: .40rem;
      font-weight: 500;
      // line-height: 1.2;
      margin-top: .65rem;
      text-align: center;
    }
    .pic_box{
      // margin-top: 0.59rem;
      position: relative;
      // margin-bottom: 0.71rem;
      .right{
        right: .2rem;
        width:2.15rem;
        margin: auto;
        img{
          width: 2.47rem;
          height: 4.34rem;
        }
      }
      
    }
    .btn_group{
      display: flex;
      align-items: center;
      justify-content: center;
      // margin-top: .48rem;
      button{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: .10rem 0;
        padding-left: .2rem;
        padding-right: .2rem;
        border-radius: .4rem;
        border: none;
        // min-width: 2.2rem;
        box-sizing: content-box;
        background-color: rgb(255, 255, 255);
        color: rgb(0, 0, 0);
        margin: 0 .05rem;
        img{
          width: .18rem;
          min-width: 20px;
          height: auto;
          margin-right: .1rem;
        }
        .text{
          text-align: left;
          p{
            font-family: 'HSSRegular';
            font-size: .1rem;
            line-height: 1.2;
            margin: 0;
          }
          .name{
            font-family: 'HSSBlod';
            font-weight: bold;
            font-size: .13rem;
            line-height: 1.2;
          }
        }
      }
    }
    .email{
      color: #fff;
      padding-top: .6rem;
      padding-bottom: .16rem;
      font-size: .12rem;
      font-weight: 500;
      text-align: center;
      font-size: 0.12rem;
      font-family: HarmonyOS Sans SC;
      p{
        margin-top: 0.08rem;
      }
    }
  }
  .footer{
    padding: .16rem .3rem .16rem;
    text-align: center;
    background-color: #000;
    
    .corpy{
      font-size: .12rem;
      color: #666;
    }
  }
}
