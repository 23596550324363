iframe {
  display: none;
}

@font-face {
  font-family: 'HarmonyOS Sans SC';
  src: url('./assets/font/HarmonyOS_Sans_Light.ttf') format('truetype');
  font-weight: 300;
}

html,body{
  width: 100%;
  background-color: #FFF1F1;
}

body{
  overflow-x: hidden;
}